import { LoaderFunctionArgs } from '@remix-run/node'
import { type MetaFunction, useLoaderData, useRouteLoaderData } from '@remix-run/react'
import { ReactNode } from 'react'

import { cmsDisclaimerFragment } from '@/app/_components/Disclaimer/index.jsx'
import { Footer } from '@/app/_components/Footer/index.jsx'
import { cmsCustomFooterFragment } from '@/app/_graphql/customFooter.js'
import { cmsCustomHeaderFragment } from '@/app/_graphql/customHeader.js'

import { withCache } from '../../.server/cache.js'
import { Blocks, blocksWithExperimentFragment } from '../../_blocks/Blocks.js'
import { Header } from '../../_components/Header/index.js'
import { Hero, heroFragment } from '../../_components/Hero/index.js'
import { CmsMetaFragment } from '../../_graphql/meta.js'
import { generateMeta } from '../../_utilities/generateMeta.js'
import { isPreviewMode } from '../../_utilities/preview-mode.server.js'
import { whereDraftStatus } from '../../lib/cms.js'
import { cmsGqlRequest, graphql } from '../../lib/cms.js'
import { captureError } from '../../lib/error-reporting.js'
import { promiseValueErrorTuple } from '../../lib/promise-value-error-tuple.js'
import { urlHasNoCache } from '../../lib/url-has-no-cache.js'
import { wrapError } from '../../lib/wrap-error.js'
import { loader as rootLoader } from '../../root.js'
import { ErrorPage } from '../error-page.js'

const parentQuery = graphql(/* GraphQL */ `
  query ParentPage($where: CmsPage_where, $draft: Boolean) {
    CmsPages(where: $where, limit: 1, draft: $draft) {
      docs {
        id
      }
    }
  }
`)

const pageQuery = graphql(
  /* GraphQL */ `
    query ChildPage($where: CmsPage_where, $draft: Boolean) {
      CmsPages(where: $where, limit: 1, draft: $draft) {
        docs {
          id
          title
          customFooter {
            ...cmsCustomFooterFragment
          }
          customHeader {
            ...cmsCustomHeaderFragment
          }
          hero {
            ...heroFragment
          }
          content {
            ...blocksWithExperimentFragment
          }
          meta {
            ...CmsMetaFragment
          }
        }
      }
    }
  `,
  [
    blocksWithExperimentFragment,
    heroFragment,
    CmsMetaFragment,
    cmsDisclaimerFragment,
    cmsCustomFooterFragment,
    cmsCustomHeaderFragment,
  ],
)

const getParentId = async ({ parent, draft }: { parent: string; draft: boolean | undefined }) => {
  const parentDoc = await cmsGqlRequest({
    query: parentQuery,
    variables: {
      draft,
      where: { slug: { equals: parent }, ...whereDraftStatus(draft) },
    },
  })

  const parentId = parentDoc.data.CmsPages?.docs?.[0]?.id

  if (!parentId) {
    throw new Response('Not Found', { status: 404 })
  }

  return parentId
}

const fetchPage = async ({ slug, parent, draft }: { slug?: string; parent?: string; draft?: boolean }) => {
  const parentId = parent ? await getParentId({ parent, draft }) : undefined

  const doc = await cmsGqlRequest({
    query: pageQuery,
    variables: {
      draft,
      where: {
        slug: { equals: slug },
        parent: parentId ? { equals: parentId } : { exists: false },
        ...whereDraftStatus(draft),
      },
    },
  })

  return doc.data.CmsPages?.docs?.[0]
}

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  let { slug, parent } = params

  if (!slug && !!parent) {
    slug = parent
    parent = undefined
  }

  slug ||= 'home'

  const preview = await isPreviewMode(request)

  const [page, pageError] = await promiseValueErrorTuple(
    withCache(`loader:pages:${slug}:${parent}`, () => fetchPage({ slug, parent, draft: preview }), {
      disable: preview || urlHasNoCache(request.url),
    }),
  )

  if (pageError instanceof Response) {
    throw pageError
  }

  if (!pageError && !page) {
    throw new Response('Not Found', { status: 404 })
  }

  if (pageError) {
    captureError(wrapError(`(${parent}).(${slug}) fetch page error`, pageError))
  }

  return {
    slug,
    page,
    parent,
    pageError,
  }
}

export default function RootSlug({ aboveFooter }: { aboveFooter?: ReactNode }) {
  const data = useLoaderData<typeof loader>()
  const { header, footer } = useRouteLoaderData<typeof rootLoader>('root')!
  const { page, pageError } = data

  if (pageError) {
    return <ErrorPage />
  }

  const { hero, content, customHeader, customFooter } = page!

  return (
    <>
      <Header header={header} customHeader={customHeader} />
      <div className="w-full grow">
        <Hero data={hero} />
        <Blocks data={content} />
        {aboveFooter}
      </div>
      <Footer footer={footer ?? null} customFooter={customFooter} />
    </>
  )
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data?.page) {
    return []
  }

  return generateMeta(data.page.meta)
}
